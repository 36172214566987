<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex px-4>
        <v-card>
          <v-layout wrap pa-4>
            <v-flex>
              <v-layout wrap pt-4>
                <v-flex xs12 sm6 md6 pr-md-3>
                  <v-layout wrap>
                    <v-flex xs2 sm2 md2 pl-2>
                      <v-img
                        v-if="dataretrived"
                        :src="baseURL+'/u/'+ projectData.logo"
                        aspect-ratio="1"
                        contain
                        height="50px"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex pl-4>
                      <span style="font-size:32px;font-weight:800">{{projectData.name}}</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap style="line-height:1.2 !important">
                    <v-flex px-2 pt-2 text-justify>
                      <span
                        style="font-size:15px;font-weight:400"
                        v-html="projectData.shortDescription"
                      ></span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap style="line-height:1.2 !important;">
                    <v-flex id="description_container" px-2 pt-2 xs12 sm12 md12 text-justify>
                      <span
                        style="font-size:13px;font-weight:400;"
                        v-html="projectData.description"
                      ></span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pt-4 pl-2 align-baseline>
                    <v-flex>
                      <v-card>
                        <v-layout wrap>
                          <v-flex px-2 text-left xs6 sm6 md6>
                            <span
                              style="font-size:12px;font-weight:400;"
                            >Raised ($ {{projectData.currentReservation}})</span>
                          </v-flex>
                          <v-flex px-2 text-right xs6 sm6 md6>
                            <span
                              style="font-size:12px;font-weight:400;color:#26af82;"
                            >{{projectData.raised}}%</span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex pb-2 px-2 xs12 sm12 md12>
                            <v-progress-linear :value="projectData.raised" color="#26af82" rounded></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex v-if="projectData._id!=null" xs12 sm6 md6 pl-2 pt-2>
                  <video width="100%" height="365" controls>
                    <source :src="baseURL+'/u/'+projectData.media" type="video/mp4" />
                  </video>
                </v-flex>
              </v-layout>
              <!-- End of 1st Section -->
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">TERM LENGTH (Years)</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >{{projectData.durationOfInvestment}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">FUNDING GOAL</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >${{formatPrice(projectData.fundingGoal)}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!-- eo1 -->
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">CURRENT RESERVATIONS</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >${{formatPrice(projectData.currentReservation)}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">MINIMUM RESERVATION</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >${{formatPrice(projectData.minimumReservation)}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!-- eo2 -->
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">MAXIMUM RESERVATION</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >${{formatPrice(projectData.maximumReservation)}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">LOCATION</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span
                            style="font-size:14px;font-weight:400"
                          >{{projectData.address}}, {{projectData.city}}, {{projectData.country}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!-- eo3 -->
                  <v-layout wrap pa-2>
                    <v-flex>
                      <v-divider />
                    </v-flex>
                  </v-layout>
                  <!-- eo4 -->
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">OPEN DATE</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:14px;font-weight:400">{{projectData.openDate}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-layout wrap align-center>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:12px;color:#A5A5A5;">CLOSING DATE</span>
                        </v-flex>
                        <v-flex xs6 sm12 md12>
                          <span style="font-size:14px;font-weight:400">{{projectData.closeDate}}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- eo5 -->
                <v-flex xs12 sm6 md6>
                  <v-layout wrap pa-2>
                    <v-flex v-if="!projectData.mypledge" xs12 sm6 md6>
                      <v-layout wrap>
                        <v-flex>
                          <span style="font-size:14px;font-weight:400">Securities up for investment</span>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex v-if="!expanded">
                          <v-card
                            outlined
                            tile
                            v-for="raise in projectData.security"
                            :key="raise._id"
                            hover
                            @click="showSecurity(raise)"
                          >
                            <v-layout wrap>
                              <v-flex>
                                <v-layout wrap>
                                  <v-flex px-2 text-left xs6 sm6 md6>
                                    <span style="font-size:12px;font-weight:400;">{{raise.name}}</span>
                                  </v-flex>
                                  <v-flex px-2 text-right xs6 sm6 md6>
                                    <span
                                      style="font-size:12px;font-weight:400;color:#26af82;"
                                    >{{raise.percentage}}%</span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap>
                                  <v-flex pb-2 px-2 xs12 sm12 md12>
                                    <v-progress-linear
                                      :value="raise.percentage"
                                      color="#26af82"
                                      rounded
                                    ></v-progress-linear>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                        <v-flex v-else>
                          <v-card outlined>
                            <v-layout wrap>
                              <v-flex>
                                <v-layout @click="hideSecurity" pa-2>
                                  <v-flex>
                                    <v-layout wrap>
                                      <v-flex text-left xs6 sm6 md6>
                                        <span
                                          style="font-size:12px;font-weight:400;"
                                        >{{security.name}}</span>
                                      </v-flex>
                                      <v-flex text-right xs6 sm6 md6>
                                        <span
                                          style="font-size:12px;font-weight:400;color:#26af82;"
                                        >{{security.percentage}}%</span>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout wrap>
                                      <v-flex pb-2 xs12 sm12 md12>
                                        <v-progress-linear
                                          :value="security.percentage"
                                          color="#26af82"
                                          rounded
                                        ></v-progress-linear>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1 sm1 md1 pa-1>
                                    <span>
                                      <v-icon small>mdi-close</v-icon>
                                    </span>
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap>
                                  <v-flex id="security_container" pb-2 px-2 xs12 sm12 md12>
                                    <span
                                      style="color:#000;font-size:12px"
                                      v-html="security.description"
                                    ></span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- /////////////////////////////////// -->
                    <!-- <v-flex v-if="!projectData.mypledge" text-xs-center xs12 sm6 md6>
                      <v-layout wrap justify-center pt-4>
                        <v-flex text-center align-center pt-12>
                          <v-btn
                            small
                            dark
                            width="220"
                            routerlink
                            :disabled="!projectData.mypledge?false:true"
                            :to="'/Project/'+projectData._id+'/Invest'"
                            :ripple="false"
                            class="text-capitalize"
                            color="#26af82"
                          >I'M INTERESTED</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>-->
                    <v-flex v-if="projectData.mypledge" xs12 sm6 md6 pl-1>
                      <v-layout wrap>
                        <v-flex>
                          <span style="font-size:14px;font-weight:400">Investment Details</span>
                        </v-flex>
                      </v-layout>
                      <v-card tile outlined>
                        <v-layout wrap px-2 py-2>
                          <v-flex md5>
                            <span style="font-size:12px;font-weight:400;color:#26af82">Status</span>
                          </v-flex>
                          <v-flex md2>
                            <span style="font-size:12px;font-weight:400;color:#26af82">-</span>
                          </v-flex>
                          <v-flex md5>
                            <span
                              style="font-size:12px;font-weight:400;"
                            >{{projectData.mypledge.pledgestatus}}</span>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap px-2 py-2>
                          <v-flex xs7 sm7 md7>
                            <span
                              style="font-size:12px;font-weight:400;color:#26af82"
                            >Total Investment</span>
                          </v-flex>
                          <v-flex xs2 sm2 md2>
                            <span style="font-size:14px;font-weight:400;color:#26af82">-</span>
                          </v-flex>
                          <v-flex xs3 sm3 md3 px-2>
                            <span
                              style="font-size:12px;font-weight:400;"
                            >$ {{projectData.mypledge.totalInvestment}}</span>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout wrap px-2 py-2>
                          <v-flex xs7 sm7 md7>
                            <span
                              style="font-size:12px;font-weight:400;color:#26af82"
                            >Total value at Maturity</span>
                          </v-flex>
                          <v-flex xs2 sm2 md2>
                            <span style="font-size:14px;font-weight:400;color:#26af82">-</span>
                          </v-flex>
                          <v-flex xs3 sm3 md3 px-2>
                            <span
                              style="font-size:12px;font-weight:400;"
                            >$ {{projectData.mypledge.totalValueAtMaturity}}</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex pl-1>
                      <v-layout v-if="projectData.mypledge" wrap>
                        <v-flex>
                          <span style="font-size:14px;font-weight:400">Securities Holding</span>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex>
                          <v-layout
                            py-0
                            v-for="(item,i) in projectData.mypledge.payment"
                            :key="i"
                            wrap
                          >
                            <v-flex v-if="item.investment>0">
                              <v-card tile outlined>
                                <v-layout px-2>
                                  <v-flex text-left>
                                    <span
                                      style="font-size:12px;font-weight:400;color:#26af82"
                                    >Security {{i+1}}</span>
                                  </v-flex>
                                </v-layout>
                                <v-layout px-2>
                                  <v-flex xs7 sm7 md7>
                                    <span style="font-size:12px;font-weight:400">Invested</span>
                                  </v-flex>
                                  <v-flex xs2 sm2 md2>
                                    <span style="font-size:12px;font-weight:400">-</span>
                                  </v-flex>
                                  <v-flex xs3 sm3 md3>
                                    <span style="font-size:12px;font-weight:400">{{item.investment}}</span>
                                  </v-flex>
                                </v-layout>
                                <v-layout px-2>
                                  <v-flex xs7 sm7 md7>
                                    <span style="font-size:12px;font-weight:400">Value at Maturity</span>
                                  </v-flex>
                                  <v-flex xs2 sm2 md2>
                                    <span style="font-size:12px;font-weight:400">-</span>
                                  </v-flex>
                                  <v-flex xs3 sm3 md3>
                                    <span
                                      style="font-size:12px;font-weight:400"
                                    >{{item.valueAtMaturity}}</span>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["projectData"],
  data() {
    return {
      data: [],
      dataretrived: false,
      media: null,
      visible: false,
      security: [],
      expanded: false
    };
  },

  mounted() {
    this.projectData.raised = (
      (this.projectData.currentReservation / this.projectData.fundingGoal) *
      100
    ).toFixed(1);
    var dt = this.projectData.openDate;
    dt = new Date(dt);
    dt = dt.toString();
    dt = dt.slice(0, 15);
    this.projectData.openDate = dt;

    var dt1 = this.projectData.closeDate;
    dt1 = new Date(dt1);
    dt1 = dt1.toString();
    dt1 = dt1.slice(0, 15);
    this.projectData.closeDate = dt1;
    this.dataretrived = true;
  },
  methods: {
    formatPrice(value) {
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showSecurity(item) {
      this.security = item;
      this.expanded = true;
    },
    hideSecurity() {
      this.expanded = false;
    }
  }
};
</script>
<style scoped>
#description_container {
  max-height: 200px;
  overflow-y: auto;
}
#security_container {
  max-height: 178px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>