<template>
  <div>
    <v-layout pt-4 wrap justify-center>
      <v-flex pa-4>
        <v-card>
          <v-layout pa-4 wrap justify-center>
            <v-flex pl-8>
              <span style="font-size:20px;font-weight:400">Risk and Disclosure</span>
            </v-flex>
          </v-layout>
          <v-layout v-if="items.length>0" px-2 pb-8 pl-md-12 wrap>
            <v-flex xs12 sm6 md4 id="month_container">
              <v-list>
                <v-list-item-group v-model="item" color="#00A972">
                  <v-layout v-for="(item, i) in items" :key="i">
                    <v-flex text-uppercase>
                      <v-list-item @click="loadDescription(item)">
                        <v-list-item-content>
                          <span style="font-size:14px;font-weight:400">{{item.name}}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-list-item-group>
              </v-list>
            </v-flex>
            <v-divider class="mx-auto" inset vertical></v-divider>
            <v-flex xs12 sm6 md7>
              <v-layout wrap pl-4>
                <v-flex text-uppercase>
                  <span style="font-size:14px;font-weight:400;color:#848484;">{{name}}</span>
                </v-flex>
              </v-layout>
              <v-layout pa-4 id="month_desc_container">
                <v-flex text-justify>
                  <span style="font-size:14px;" v-html="description"></span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else wrap justify-center px-2 pb-8 pl-md-12>
            <v-flex xs12 sm12 md12>
              <span style="font-size:14px;font-weight:400">No Risk and Disclosure Details</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["projectData"],
  data() {
    return {
      item: 0,
      items: [],
      name: "",
      description: ""
    };
  },
  beforeMount() {
    this.items = this.projectData.risk;
    if (this.items.length > 0) {
      this.name = this.items[0].name;
      this.description = this.items[0].description;
    }
  },
  methods: {
    loadDescription(item) {
      this.name = item.name;
      this.description = item.description;
    }
  }
};
</script>
<style scoped>
#month_container {
  max-height: 300px;
  overflow-y: auto;
}
#month_desc_container {
  min-height: 276px;
  max-height: 276px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>