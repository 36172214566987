<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex pa-4>
        <v-layout wrap py-4>
          <v-flex text-center>
            <span style="font-size:24px;font-weight:400">Investment Summary</span>
          </v-flex>
        </v-layout>
        <v-layout v-if="projectData.investmentSummary" wrap justify-center py-4>
          <v-flex text-center xs12 sm8 md8>
            <span style="font-size:16px;font-weight:200" v-html="projectData.investmentSummary"></span>
          </v-flex>
          <v-flex pt-4 text-center xs12 sm8 md8>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap justify-center py-2>
          <v-flex xs12 sm12 md12 text-center>
            <span style="font-size:14px;font-weight:400">No Investment Summary</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["projectData"],
  data() {
    return {};
  }
};
</script>