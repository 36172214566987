<template>
  <div>
    <v-layout pt-4 wrap>
      <v-flex pa-4>
        <v-card>
          <v-layout pt-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:24px;font-weight:500">Team</span>
            </v-flex>
          </v-layout>
          <v-layout v-if="teams.length>0" wrap justify-center py-4>
            <v-flex px-2>
              <hooper
                style="height:350px!important;"
                :infiniteScroll="true"
                :itemsToSlide="1"
                :progress="false"
                :autoPlay="true"
                :centerMode="true"
                :settings="hooperSettings"
                :wheelControl="false"
              >
                <slide
                  v-for="(card, item) in teams"
                  v-bind:key="item"
                  style="padding-right:30px!important"
                >
                  <v-container fluid grid-list-sm style="height:450px!important">
                    <v-layout align-center>
                      <v-flex xs12 md12 pl-8>
                        <v-layout wrap pt-2>
                          <v-flex text-center style="height:150px">
                            <v-img
                              v-bind:src="baseURL+'/u/'+card.photo"
                              aspect-ratio="1"
                              contain
                              height="90%"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <!-- <img
                              class="menu-icon"
                              v-bind:src="baseURL+'/u/'+card.photo"
                              width="100%"
                              height="100%"
                            />-->
                          </v-flex>
                        </v-layout>
                        <v-layout wrap md6 pt-2>
                          <v-flex text-center>
                            <span style="font-size:16px;font-weight:500;">{{card.name}}</span>
                          </v-flex>
                        </v-layout>
                        <v-layout style="height:150px" wrap md12 pt-2>
                          <v-flex text-center style="line-height:1">
                            <span
                              style="font-size:12px;font-weight:400;"
                              v-html="card.description.substring(0,100)+'...'"
                            ></span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
                <hooper-navigation
                  class="hooper-indicators"
                  v-if="this.value1"
                  slot="hooper-addons"
                  style="padding-left:80px!important;"
                ></hooper-navigation>
              </hooper>
            </v-flex>
          </v-layout>
          <v-layout v-else wrap justify-center px-2 pb-8 pl-md-12>
            <v-flex xs12 sm12 md12>
              <span style="font-size:14px;font-weight:400">No Team Members</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  props: ["projectData"],
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data() {
    return {
      data: [],
      value1: null,
      teams: [],
      part: [],
      hooperSettings: {
        playSpeed: 1000,
        trimWhiteSpace: true,
        itemsToShow: 1,
        centerMode: true,
        breakpoints: {
          600: {
            centerMode: true,
            itemsToShow: 2
          },
          800: {
            centerMode: true,
            itemsToShow: 3
          },
          1000: {
            centerMode: true,
            itemsToShow: 4,
            initialSlide: 1,
            trimWhiteSpace: true,
            infiniteScroll: false,
            mouseDrag: true,
            touchDrag: true,
            autoPlay: true
          }
        }
      }
    };
  },
  mounted: function() {
    this.data = this.projectData;
    this.teams = this.projectData.team;
    this.part = this.projectData.team.length;
    if (this.teams.length < 4) {
      this.value1 = false;
    } else this.value1 = true;
    if (this.teams.length < 4) {
      this.value1 = true;
    } else this.value1 = false;
    if (this.projectData.team.length > 4) {
      this.hooperSettings.breakpoints["1000"].infiniteScroll = true;
    }
  },
  methods: {}
};
</script>
<style>
h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
/* .hooper-indicators {
  background-color: #ffffff !important;
} */

.hooper {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  font-size: 30px;
  border-radius: 10px;
}
.layout:focus {
  outline-width: 0px !important;
  outline: none !important;
}
/* .hooper-track {
  transform: none !important;
} */
</style>
