<template>
  <div style="background-color:#F9F9F9">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />

    <v-snackbar v-model="showsnackbar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showsnackbar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-space-around>
      <v-flex xs12 md12 pa-0>
        <!-- <v-card id="app_height" outlined tile> -->
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md2>
            <sidebar />
          </v-flex>
          <v-flex id="app_signup" xs12 sm12 md10 pl-2>
            <ProjectOverview
              v-if="dataretrived"
              v-bind:projectData="projectData"
              style="padding-top:20px"
              id="ProjectOverview"
            />
            <InvestmentSummary
              v-if="dataretrived"
              v-bind:projectData="projectData"
              id="InvestmentSummary"
            />
            <KeyTargetMetrics
              v-if="dataretrived"
              v-bind:projectData="projectData"
              id="KeyTargetMetrics"
            />
            <KeyPartners v-if="dataretrived" v-bind:projectData="projectData" id="KeyPartners" />
            <ProgressToDate
              v-if="dataretrived && projectData.progress.length>1"
              v-bind:projectData="projectData"
              id="ProgressToDate"
            />
            <FundingDetails
              v-if="dataretrived"
              v-bind:projectData="projectData"
              id="FundingDetails"
            />
            <RiskAndDisclosure
              v-if="dataretrived"
              v-bind:projectData="projectData"
              id="RiskAndDisclosure"
            />
            <Documents v-if="dataretrived" v-bind:projectData="projectData" id="Documents" />
            <Investors v-if="dataretrived" v-bind:projectData="projectData" id="Investors" />
            <Team v-if="dataretrived" v-bind:projectData="projectData" id="Team" />
            <FAQ v-if="dataretrived" v-bind:projectData="projectData" id="FAQ" />
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Sidebar from "./ViewProject/Sidebar";
import ProjectOverview from "./ViewProject/ProjectOverview";
import InvestmentSummary from "./ViewProject/InvestmentSummary";
import KeyTargetMetrics from "./ViewProject/KeyTargetMetrics";
import KeyPartners from "./ViewProject/KeyPartners";
import ProgressToDate from "./ViewProject/ProgressToDate";
import FundingDetails from "./ViewProject/FundingDetails";
import RiskAndDisclosure from "./ViewProject/RiskAndDisclosure";
import Documents from "./ViewProject/Documents";
import Investors from "./ViewProject/Investors";
import Team from "./ViewProject/Team";
import FAQ from "./ViewProject/FAQ";

export default {
  props: ["id"],
  components: {
    VueElementLoading,
    Sidebar,
    ProjectOverview,
    InvestmentSummary,
    KeyTargetMetrics,
    KeyPartners,
    ProgressToDate,
    FundingDetails,
    RiskAndDisclosure,
    Documents,
    Investors,
    Team,
    FAQ
  },
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      timeout: 5000,
      msg: null,
      dataretrived: false,
      projectData: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/get",
        params: {
          id: this.id
        },
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.dataretrived = true;
          if (response.data.status === true) {
            this.projectData = response.data.data;
            // console.log("Project Data", this.projectData);
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    }
  }
};
</script>
<style>
#app_signup {
  background-image: url("../../assets/home_header.png");
  background-repeat: no-repeat;
  background-size: contain;
  /* background-size: cover; */
  /* background-attachment: fixed; */
}
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
  }
}
</style>