<template>
  <div>
    <v-layout pt-4 wrap justify-center>
      <v-flex pa-4 text-center>
        <span style="font-size:20px;font-weight:400">How can we help ?</span>
      </v-flex>
    </v-layout>
    <v-layout px-4 wrap justify-center pt-5>
      <v-flex xs12 sm-8 md6>
        <v-text-field
          single-line
          solo
          label="Search help articles"
          color="#26af82"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout xs12 sm12 md6 px-4 wrap justify-center pt-5>
      <!-- <v-flex></v-flex> -->
      <!-- <v-flex></v-flex> -->
      <v-flex xs2 sm2 md2>
        <v-card>
          <v-layout pa-5>
            <v-flex text-center>
              <v-img src="../../../assets/icons/flag.png" aspect-ratio="1" contain height="30">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
          </v-layout>
          <v-layout pb-5>
            <v-flex text-center>
              <span style="font-size:14px;font-weight:400">Getting Started</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex xs4 sm2 md2 px-5>
        <v-card>
          <v-layout pa-5>
            <v-flex text-center>
              <v-img src="../../../assets/icons/loading.png" aspect-ratio="1" contain height="30">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
          </v-layout>
          <v-layout pb-5>
            <v-flex text-center>
              <span style="font-size:14px;font-weight:400">Project Status</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex xs2 sm2 md2>
        <v-card>
          <v-layout pa-5>
            <v-flex text-center>
              <v-img src="../../../assets/icons/notebook.png" aspect-ratio="1" contain height="30">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
          </v-layout>
          <v-layout pb-5>
            <v-flex text-center>
              <span style="font-size:14px;font-weight:400">User Guide</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <!-- <v-flex></v-flex> -->
      <!-- <v-flex></v-flex> -->
    </v-layout>
    <v-layout px-4 wrap justify-space-around pt-10>
      <v-flex xs12 sm12 md10>
        <span style="font-size:20px;font-weight:400">FAQ</span>
      </v-flex>
    </v-layout>
    <v-layout px-4 pt-5 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md10>
        <v-expansion-panels wrap justify-space-around pt-5 elevation-0>
          <v-expansion-panel v-for="(item,i) in faq" :key="i">
            <v-expansion-panel-header>
              <span style="font-weight:500">{{item.question}}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>{{item.answer}}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["projectData"],
  data() {
    return {
      faq: []
    };
  },
  mounted: function() {
    this.faq = this.projectData.faq;
  }
};
</script>