<template>
  <div>
    <v-layout pt-4 wrap justify-center>
      <v-flex pa-4>
        <v-card>
          <v-layout pa-4 wrap justify-center>
            <v-flex pl-8>
              <span style="font-size:20px;font-weight:400">Documents</span>
            </v-flex>
          </v-layout>
          <v-layout v-if="items.length>0" px-2 pb-8 pl-md-12 wrap>
            <v-flex xs12 sm6 md4 id="month_container">
              <v-list>
                <v-list-item-group v-model="item" color="#00A972">
                  <v-layout v-for="(item, i) in items" :key="i">
                    <v-flex text-uppercase>
                      <v-list-item @click="loadDescription(item)">
                        <v-list-item-content>
                          <span style="font-size:14px;font-weight:400">{{item.name}}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-list-item-group>
              </v-list>
            </v-flex>
            <v-divider class="mx-auto" inset vertical></v-divider>
            <v-flex xs12 sm6 md7>
              <v-layout wrap pl-4>
                <v-flex text-uppercase>
                  <span style="font-size:14px;font-weight:400;color:#848484;">{{docCategory}}</span>
                </v-flex>
              </v-layout>
              <v-layout wrap pa-4 id="month_desc_container">
                <v-flex xs12 sm6 md4 v-for="(item,i) in docs" :key="i" text-center>
                  <a :href="baseURL+'/u/'+item.filename" download target="_blank">
                    <v-img src="../../../assets/documents/1.png" height="150px" contain>
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <span style="font-size:14px;font-weight:500;color:#000">{{item.name}}</span>
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else wrap justify-center px-2 pb-8 pl-md-12>
            <v-flex xs12 sm12 md12>
              <span style="font-size:14px;font-weight:400">No Documents</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["projectData"],
  data() {
    return {
      item: 0,
      items: [],
      docCategory: null,
      docs: []
    };
  },
  beforeMount() {
    this.items = this.projectData.document;
    if (this.items.length > 0) {
      this.docCategory = this.items[0].name;
      this.docs = this.items[0].category;
    }
  },
  methods: {
    loadDescription(item) {
      this.docCategory = item.name;
      this.docs = item.category;
    }
  }
};
</script>
<style scoped>
#month_container {
  max-height: 300px;
  overflow-y: auto;
}
#month_desc_container {
  min-height: 276px;
  max-height: 276px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>