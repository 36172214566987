<template>
  <div>
    <v-layout pt-4 wrap justify-center>
      <v-flex pa-4>
        <v-card>
          <v-layout pa-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:20px;font-weight:400">Key Investors</span>
            </v-flex>
          </v-layout>
          <v-layout px-2 pb-8 pl-md-12 wrap justify-space-around>
            <v-flex></v-flex>
            <v-flex></v-flex>
            <v-flex>
              <v-layout>
                <v-flex text-center>
                  <v-avatar>
                    <v-img src="../../../assets/person1.png">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex text-center>
                  <span style="font-size:14px;font-weight:400">Bradley Cooper</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-flex text-center>
                  <v-avatar>
                    <v-img src="../../../assets/person2.png">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex text-center>
                  <span style="font-size:14px;font-weight:400">Chris Evans</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-flex text-center>
                  <v-avatar>
                    <v-img src="../../../assets/person3.png">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex text-center>
                  <span style="font-size:14px;font-weight:400">Henry Cavil</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex></v-flex>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
#month_container {
  max-height: 300px;
  overflow-y: auto;
}
#month_desc_container {
  max-height: 300px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>