<template>
  <v-app id="inspire" class="fixedSidebar">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      class="fixedSidebr"
      style="background-color:#06140F;"
    >
      <v-layout wrap>
        <v-flex xs12 sm12 md12>
          <v-list dense>
            <template v-for="item in items">
              <v-list-item
                color="#00a972"
                :key="item.text"
                router-link
                :to="item.id"
                @click="changeStyle"
              >
                <v-list-item-content style="padding-left:20px;">
                  <v-list-item-title style="color:#fff;font-weight:300">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-content
                  v-scroll-to="{ element: item.id, duration: 1000 }"
                  style="padding-left:20px;"
                >
                  <v-list-item-title
                    style="color:#fff;font-weight:300"
                    @click="changeStyle"
                  >{{ item.text }}</v-list-item-title>
                </v-list-item-content>-->
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      drawer: null,
      items: [
        {
          text: "Project Overview",
          id: "#ProjectOverview"
        },
        {
          text: "Investment Summary",
          id: "#InvestmentSummary"
        },
        {
          text: "Key Target Metrics",
          id: "#KeyTargetMetrics"
        },
        {
          text: "Key Partners",
          id: "#KeyPartners"
        },
        {
          text: "Progress to date",
          id: "#ProgressToDate"
        },
        {
          text: "Funding Details",
          id: "#FundingDetails"
        },

        {
          text: "Risk & Disclosure",
          id: "#RiskAndDisclosure"
        },
        {
          text: "Documents",
          id: "#Documents"
        },
        {
          text: "Investors",
          id: "#Investors"
        },
        {
          text: "Team",
          id: "#Team"
        },
        {
          text: "FAQ",
          id: "#FAQ"
        }
      ],
      msg: null
    };
  },
  methods: {
    changeStyle() {
      // document
      //   .getElementById(window.location.hash.substring(1))
      //   .scrollIntoView();
      window.scroll(
        0,
        this.findPos(document.getElementById(window.location.hash.substring(1)))
      );
    },
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        curtop = curtop - 68;
        return [curtop];
      }
    }
  }
};
</script>
<style scoped>
#inspire {
  height: 100vh !important;
}

@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    position: fixed;
    width: 200px !important;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
</style>